import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import ArticlesGrid from "../components/articles-grid"
import { SEO } from "../components/seo"
import Headings from "../components/headings"

const BlogIndex = () => {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: {fields: createdDate, order: DESC}) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `)

  const title = "Analytics and Data - Blog"
  const description = strapiGlobal.siteDescription
  return (
    <Layout>
      <SEO  title={title} description={description} 
      image="https://res.cloudinary.com/jackalytics/image/upload/v1676994313/default_image_10429e10bf.jpg"/>
      <div className="container">
        <Headings
        title={title} description={description}
        />
      </div>
      <main className="pb-6">
        <ArticlesGrid articles={allStrapiArticle.nodes} />
      </main>
    </Layout>
  )
}

export default BlogIndex
